import axios from "axios";

const game = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //assign game
    assignGame(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        };
        axios
          .post(`${API_URL}/assign-game`, data, config)
          .then(res => {
            resolve(res);
            console.log(res);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    }
  }
};
export default game;
