import Vue from "vue";
import store from "../store/index.js";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: "Dashboard",
    component: () => import("../views/Home.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        //STUDENT
        path: "/",
        name: "Dasbor",
        component: () => import("../views/General/dashboard.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/update-profile",
        name: "Update Profile",
        component: () => import("../views/Student/udpdateProfile.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/edit-profile",
        name: "Edit Profile",
        component: () => import("../views/General/editProfile.vue"),
        meta: { requiresAuth: true }
      },

      {
        path: "/assignment",
        name: "Assignment",
        component: () => import("../views/Student/assignments.vue"),
        meta: { requiresAuth: true }
      },

      {
        path: "/discussion",
        name: "Discussion",
        component: () => import("../views/Student/Disscusion.vue"),
        meta: { requiresAuth: true }
      },

      {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/General/Profile.vue"),
        meta: { requiresAuth: true }
      },

      {
        //GROUP
        path: "/timetable",
        name: "Time Table",
        component: () => import("../views/Group/TimeTable.vue"),
        meta: { requiresAuth: true }
      },

      {
        //MENTOR
        path: "/timetable/:idGroup",
        name: "Time Table Mentor",
        component: () => import("../views/Mentor/TimeTableMentor.vue"),
        meta: { requiresAuth: true }
      },

      {
        path: "/file",
        name: "Files",
        component: () => import("../views/Group/File.vue"),
        meta: { requiresAuth: true }
      },

      {
        //ADMIN
        path: "/list-admin",
        name: "Admin",
        component: () => import("../views/Admin/listAdmin.vue"),
        meta: { requiresAuth: true }
      },

      {
        //ADMIN
        path: "/broadcast",
        name: "broadcast",
        component: () => import("../views/General/broadcast.vue"),
        meta: { requiresAuth: true }
      },

      {
        //ADMIN
        path: "/list-feedback",
        name: "List Feedback",
        component: () => import("../components/Admin/listFeedback.vue"),
        meta: { requiresAuth: true }
      },

      {
        path: "/list-teacher",
        name: "Widyaiswara",
        component: () => import("../views/Admin/listTeacher.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/list-mentor",
        name: "Mentor",
        component: () => import("../views/Teacher/listMentor.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/list-Module",
        name: "Modul Pembelajaran",
        component: () => import("../views/Teacher/Module.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/add-module",
        name: "Tambah Msdule",
        component: () => import("../components/Teacher/stepperAddModule.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/module-detail/:idModule",
        name: "Detil Modul",
        component: () => import("../views/Teacher/moduleDetail.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/list-event",
        name: "Pelatihan",
        component: () => import("../components/Teacher/listEvent.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/group-detail/:idGroup",
        name: "Detil Kelompok",
        component: () => import("../components/Teacher/groupDetail.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/event-detail/:idEvent",
        name: "Detil Pelatihan",
        component: () => import("../components/Teacher/EventDetail.vue"),
        meta: { requiresAuth: true }
      },
      // TEACEHR--
      {
        path: "/management",
        name: "Pengelolaan akun",
        component: () => import("../views/Teacher/managementAccount.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/steper",
        name: "createModul",
        component: () => import("../components/Teacher/stepperAddModule.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/narasi",
        name: "narasi",
        component: () => import("../components/Student/narasi.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/case/:id",
        name: "detail kasus",
        component: () => import("../components/Student/caseDetail.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/submission",
        name: "submission",
        component: () => import("../views/Student/Submission.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("../views/General/Feedback.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/logs",
        name: "activity",
        component: () => import("../views/General/logTeacher.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/member",
        name: "member",
        component: () => import("../views/Mentor/Member.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/umpan-balik",
        name: "umpan balik",
        component: () => import("../views/Student/feedForApp.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/manual",
        name: "manual",
        component: () => import("../views/General/Manual.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/leaderboard",
        name: "leaderboard",
        component: () =>
          import("../components/Student/Leaderboard/leaderboardPage.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },
  //game

  {
    path: "/raynor",
    name: "Raynor",
    component: () => import("../views/Game/Raynor.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/apta",
    name: "Apta",
    component: () => import("../views/Game/Apta.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/smara",
    name: "Smara",
    component: () => import("../views/Game/Smara.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/yoga",
    name: "Yoga",
    component: () => import("../views/Game/Yoga.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/pinada",
    name: "Pinada",
    component: () => import("../views/Game/Pinada.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/ligar",
    name: "Ligar",
    component: () => import("../views/Game/Ligar.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/wiksa",
    name: "Wiksa",
    component: () => import("../views/Game/Wiksa.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/afif",
    name: "afif",
    component: () => import("../views/Game/Tikus.vue"),
    // meta: { requiresAuth: true }
  },
  //game done
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { requiresVisitor: true }
  },

  {
    path: "/password/:token",
    name: "Reset",
    component: () => import("../views/resetPwd.vue"),
    meta: { requiresVisitor: true }
  },
  {
    path: "*",
    name: "pageNotFound",
    component: () => import("../views/pageNotFound.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/end-event",
    name: "End_evt",
    component: () => import("../views/endEvt.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/information",
    name: "Information",
    component: () => import("../components/_base/information.vue"),
    meta: { requiresAuth: true }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.getUser) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.getRole == 4 && store.getters.getUser) {
      console.log(store.getters.percent);
      if (store.getters.percent !== 100) {
        next({
          path: "/update-profile"
        });
      } else {
        next({
          path: "/"
        });
      }
    } else if (store.getters.getUser) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
