<template>
  <v-app>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  })
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  scrollbar-color: #888;
  scrollbar-width: thin;
}
p {
  margin: 0;
  padding: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
