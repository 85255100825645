import axios from "axios";

const leaderboard = {
  namespaced: true,
  state: () => ({
    leaderboardY: null, //leaderboard individu
    leaderboardX: null //leaderboard kelompok
  }),
  mutations: {
    LEADERBOARD_Y(state, data) {
      let rank = [...data.data];
      rank.map((el) => {
        if(el.badge != null) {
          return el.badge = el.badge.split(',');
        }
      })
      state.leaderboardY = rank;
    },
    LEADERBOARD_X(state, data) {
      state.leaderboardX = data;
    }
  },
  actions: {
    // leaderboard individu
    leadY(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        };
        axios
          .post(`${API_URL}/user/member-lead`, data, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            // console.log(data)
            context.commit("LEADERBOARD_Y", data);
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    leadX(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        };
        axios
          .post(`${API_URL}/user/grup-lead`, data, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            // console.log(data)
            context.commit("LEADERBOARD_X", data);
          })
          .catch(error => {
            console.log(error);
          });
      });
    }
  }
};
export default leaderboard;
