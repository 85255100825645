import axios from "axios";

const existUser = {
  namespaced: true,
  state: {
    errMsg: "",
    listUser: null,
    success: null,
  },
  getters: {},
  mutations: {
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
    RESOLVE_MESSAGE(state, data) {
      state.success = data;
    },
    LIST_USER(state, data) {
      state.listUser = data;
    },
  },
  actions: {
    // list user exist
    listExistUser(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .get(`${API_URL}/user/to-clone?page=1&per_page=10`, config)
          .then((res) => {
            resolve(res.data);
            const result = res.data;
            context.commit("LIST_USER", result);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // pilih exist user
    pickUser(context, data) {
      return new Promise((resolve, reject) => {
        let id_group = data.id_group;
        let id = new FormData();
        id.append('id', data.id)
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/user/${id_group}/clone`, id, config)
          .then((res) => {
            resolve(res.data);
            let data = res.data;
            context.commit("RESOLVE_MESSAGE", data);
          })
          .catch((error) => {
            console.log(error);
            reject(error.data.response);
            context.commit("ERR_MSG", error);
          });
      });
    },
  },
};
export default existUser;
