import axios from "axios";

const leaderboard = {
  namespaced: true,
  state: () => ({
    broadcast: null, //broadcast
    an_broadcast: null, //broadcast
    resultMsg: null,
  }),
  mutations: {
    GET_BROADCAST(state, data) {
      state.broadcast = data;
    },
    VIEW_BROADCAST(state, data) {
      state.an_broadcast = data;
    },
    RESOLVE(state, data) {
      state.resultMsg = data
    },
  },
  actions: {
    // leaderboard individu
    getBroadcast(context) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        };
        axios
          .get(`${API_URL}/user/pengumuman/list`,  config)
          .then(res => {
            resolve(res);
            const data = res.data;
            // console.log(data)
            context.commit("GET_BROADCAST", data);
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    createBroadcast(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        };
        axios
          .post(`${API_URL}/user/pengumuman`, data, config)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            // console.log(data)
            context.commit("RESOLVE", data);
          })
          .catch(error => {
            console.log(error);
          });
      });
    }
  }
};
export default leaderboard;
