import axios from "axios";

const feedback = {
  namespaced: true,
  state: {
    errMsg: "",
    listFeedback: null,
    feedbackbyid: null,
    lastFeed: null,
  },
  getters: {},
  mutations: {
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
    LIST_FEEDBACK(state, data) {
      state.listFeedback = data;
    },
    FEEDBACK_BYID(state, data) {
      state.feedbackbyid = data;
    },
    LAST_PAGE_FEED(state, data) {
      state.lastFeed = data.last_page;
    },
  },
  actions: {
    //feed back untuk aplikasi
    feedbackForApp(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        };
        axios
          .post(`${API_URL}/user/feedback`, data, config)
          .then(res => {
            resolve(res);
            console.log(res);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error);
          });
      });
    },
    // list feed back
    listFeedback(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        };
        axios
          .post(`${API_URL}/user/feedback/list`, data, config)
          .then(res => {
            resolve(res);
            let feed = res.data;
            console.log(data);
            context.commit("LAST_PAGE_FEED", feed);
            if (data.page > 1) {
              context.state.listFeedback.push(...feed.data);
            } else {
              context.commit("LIST_FEEDBACK", feed.data);
              context.state.listFeedback = feed.data;
            }
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error);
          });
      });
    },
    // feedback detail
    FeedbackDetail(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        };
        axios
          .post(`${API_URL}/user/${data}/feedback`, config)
          .then(res => {
            resolve(res);
            let data = res.data;
            context.commit("FEEDBACK_BYID", data);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error);
          });
      });
    }
  }
};
export default feedback;
