import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";

import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";

import VueCookies from "vue-cookies";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import VueSnip from "vue-snip";
import VueCarousel from "vue-carousel";
import VueObserveVisibility from "vue-observe-visibility";
import Popover from "vue-js-popover";

Vue.use(Popover);
Vue.use(VueObserveVisibility);
Vue.use(VueCarousel);

Vue.use(VueSnip);

Vue.use(Chartkick.use(Chart));
Vue.use(VueCookies);
Vue.use(VueApollo);

Vue.config.productionTip = false;

Vue.$cookies.config("7d");

// GET TOKEN
const getHeaders = () => {
  const headers = {};
  const token = store.state.token;
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return headers;
};

let API_URL = process.env.VUE_APP_GRAPHQL;

const link = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink({
    uri: `${API_URL}/graphql`,
    fetch,
    headers: getHeaders()
  }),
  new HttpLink({
    uri: `${API_URL}/graphql`,
    fetch,
    headers: getHeaders()
  })
);
export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  })
});

const apolloProvider = new VueApollo({
  defaultClient: client
});

//web soket
// import Echo from 'laravel-echo';
// window.io = require('socket.io-client')

// window.Echo = new Echo({
//   broadcaster: "socket.io",
//   host: API_URL,
//   auth: { headers: getHeaders() }
// });

new Vue({
  router,
  apolloProvider,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
