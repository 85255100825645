import Vue from "vue";
import Vuex from "vuex";
import leaderboard from "./module/leaderboard";
import game from "./module/game";
import feedback from "./module/feedback";
import existUser from "./module/ExistUser";
import broadcast from "./module/broadcast"

Vue.use(Vuex);
import axios from "axios";

export default new Vuex.Store({
  state: {
    loading: false,
    errMsg: "", //error message
    snack: false, //snackbar
    token: localStorage.getItem("token") || null, //token
    user_id: localStorage.getItem("id"), //token
    role_id: localStorage.getItem("role_id"), //role id
    idGroup: null, //id group
    chart: [], //data chart
    visible: [], //perubahan,
    precentage: localStorage.getItem("precentage") || null, //presentase profile
    initial: "", //initial
    type_module: "", //tipe modul
    module: null, //modulid
    group_id: "", //grup id untuk apa lupa
    thread_id: null, //thread id untuk jaga2 kali perlu
    allDataChat: null, //list semua data chat, like pagination or etc..
    list_chat: null, //list thread maksudnya
    list_contact: null, //list conatct
    shortChat: null, //shortchat tp kayaknya ga di pake
    chatNull: null, //ini juga kayaknya ga di pake
    allChat: [], //hanya info chat saja tidak ada pagination dll
    narasi: "", //narasi
    dataCase: null, //data kasus
    countDay: null, //menghitung hari, detik demi detik.. ga deng.. hehe
    poin: null, //poin
    userProfile: null, // data profile
    myFile: null, // menampung file sendiri
    fileGroup: null, // file group
    submissionX: null,
    submissionY: null,
    badge_case: null,
    pathGame: [],
    badge_tugas: null,
    tugas_grup: null,
    badge_task: null,
    badge_poin: null,
    feeds: null, //feeds
    link_file: null,
    url: null,
    fileTipe: null,
    lastLog: 1,
    no_soal: null,
    existing_badge: null,
    end_evt: null,
    start_evt: null,
    unread: 0,
    totalPoin: null,
  },
  getters: {
    getUser(state) {
      return state.token !== null && state.token !== undefined;
    },
    getRole(state) {
      return state.role_id;
    },
    percent(state) {
      return state.precentage;
    },
  },
  mutations: {
    GET_TOTAL_POIN(state, data) {
      state.totalPoin = data;
    },
    UNREAD(state, data) {
      state.unread = data;
    },
    START_EVENT(state, data) {
      state.start_evt = data;
    },
    END_EVENT(state, data) {
      state.end_evt = data;
    },
    NO_SOAL(state, data) {
      state.no_soal = data;
    },
    LAST_PAGE_LOG(state, data) {
      state.lastLog = data.last_page;
    },
    PATH_GAME(state, data) {
      state.pathGame = data;
    },
    FEEDS(state, data) {
      state.feeds = data;
    },
    SUBMISSION_X(state, data) {
      state.submissionX = data.data;
    },
    SUBMISSION_Y(state, data) {
      state.submissionY = data.data;
    },
    MYFILE(state, data) {
      state.myFile = data;
    },
    FILEGROUP(state, data) {
      state.fileGroup = data;
    },
    SEND_LINK(state, data) {
      state.link_file = data;
    },
    SEND_URL(state, data) {
      state.url = data;
    },
    FILE_TIPE(state, data) {
      state.fileTipe = data;
    },
    USER_DATA(state, profile) {
      state.userProfile = profile;
    },
    //nampung poin di state biar bisa di cek di mana aja
    POIN(state, poin) {
      state.poin = poin;
    },
    //menghhitung hari
    COUNT_DAY(state, day) {
      state.countDay = day;
    },
    PUSH_NARASI(state, data) {
      state.narasi = data;
    },
    MODULE(state, data) {
      state.module = data;
    },
    //list contact
    LIST_CONTACT(state, data) {
      state.list_contact = data;
    },
    //list thread
    LIST_CHAT(state, data) {
      state.list_chat = data;
    },
    //ambil data chat dari api
    GET_CHAT(state, data) {
      state.allDataChat = data;
    },
    CHAT_NULL(state, data) {
      state.chatNull = data;
    },
    THREAD_ID(state, data) {
      state.thread_id = data;
    },
    GROUP_ID(state, data) {
      state.group_id = data;
    },
    INITIAL(state, ava) {
      state.initial = ava;
    },
    BADGES_CASE(state, data) {
      state.badge_case = data;
    },
    EXISTING_BADGES(state, data) {
      state.existing_badge = data;
    },
    BADGES_TUGAS(state, data) {
      state.badge_tugas = data;
    },
    REPONSE_TUGAS_KELOMPOK(state, data) {
      state.tugas_grup = data;
    },
    BADGES_TASK(state, data) {
      state.badge_task = data;
    },
    BADGES_POIN(state, data) {
      state.badge_poin = data;
    },
    // percent
    PRECENTAGE(state, data) {
      state.precentage = data;
    },
    // get role user
    GET_ROLE(state, role) {
      state.role_id = role;
    },
    // catch eroor
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
    // change value sncakbar
    SNACKY(state, value) {
      state.snack = value;
    },
    // get id group
    ID_GROUP(state, id) {
      state.idGroup = parseInt(id);
    },
    DATA_CASE(state, data) {
      state.dataCase = data;
    },
    RESET_CHART(state) {
      state.chart = null;
    },
    // get data of chart
    CHART(state, { data, idx }) {
      let daysTo = parseInt(idx); //get hari pelatihan ke ...
      let visible = data.visible.map((el) => {
        return parseInt(el);
      }); //visible yang available
      console.log(visible);
      let shift = null; //menampung perubahan data
      for (let i = 0; i < visible.length; i++) {
        if (daysTo == visible[i]) {
          shift = visible[i];
          break;
        } else if (i <= visible.length && i !== 0) {
          if (daysTo <= visible[i]) {
            console.log("hari di antara visible " + visible[i]);
            shift = visible[i - 1]; //hari di antara visible
            break;
          }
        }
        if (i === visible.length - 1) {
          console.log("hari habis " + visible[i]);
          shift = visible[i]; //hari setelah visible terakhir
          break;
        }
      }
      console.log("perubahan data ke " + shift);
      if (shift !== null) {
        if (shift === "x") {
          state.chart = "x";
        } else {
          if (data.data[shift].dataset) {
            state.chart = data.data[shift].dataset;
          } else {
            state.chart = data.data[shift].narasi;
          }
        }
      }
    },
    // get change perday on chart
    VISIBLE(state, data) {
      state.visible = data.visible;
    },
  },
  actions: {
    //login
    login(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .post(`${API_URL}/login`, data)
          .then((res) => {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role_id", res.data.user.role_id);
            localStorage.setItem("id", res.data.user.id);
            localStorage.setItem("precentage", res.data.user.nama_lengkap);
            context.commit("GET_ROLE", res.data.user.role_id);
            context.commit("PRECENTAGE", res.data.user.precentage);
            context.state.loading = true;
            context.state.token = res.data.token;
            resolve(res);
          })
          .catch((error) => {
            console.log(error.response.data);
            context.commit("ERR_MSG", error.response.data);
            context.state.snack = true;
            setTimeout(() => (context.state.snack = false), 5000);
          });
      });
    },

    //preview perubahan chart
    getTotalPoin(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .get(`${API_URL}/user/akumulasi-poin`, config)
          .then((res) => {
            resolve(res);
            context.commit('GET_TOTAL_POIN', res.data)
          })
          .catch((error) => {
            console.log(error);
            console.log("masuk catch");
          });
      });
    },
    //preview perubahan chart
    previewChart(context, dataChart) {
      const chart = dataChart.chart;
      const id = dataChart.id;
      console.log(dataChart);
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .get(`${API_URL}/simulasi-chart/${chart}/modul-kasus/${id}`)
          .then((res) => {
            resolve(res);
            const data = res.data;
            let idx = dataChart.q ? dataChart.q : data.visible[0];
            context.commit("CHART", { data, idx });
            context.commit("VISIBLE", data);
          })
          .catch((error) => {
            console.log(error);
            console.log("masuk catch");
          });
      });
    },
    //resend email
    resendEmail(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .post(`${API_URL}/resend`, data, config)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    // send chat
    sendChat(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .post(`${API_URL}/user/kirim-pesan`, data, config)
          .then((res) => {
            resolve(res);
            context.commit("THREAD_ID", res.data.thread_id);
          })
          .catch((error) => {
            console.log(error);
            console.log("masuk catch");
          });
      });
    },

    // fetch chat
    fetchChat(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        context.commit("THREAD_ID", data.id);
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        axios
          .get(`${API_URL}/user/${data.id}/view-thread?page=${data.page}`, config)
          .then((res) => {
            resolve(res);
            const Alldata = res.data;
            if (data.page > 1) {
              context.commit("GET_CHAT", Alldata);
              context.state.allChat.push(...res.data.pesan.data);
            } else {
              context.commit("GET_CHAT", Alldata);
              context.state.allChat = res.data.pesan.data;
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("masuk catch");
          });
      });
    },

    //get list chat
    listChat(context) {
      return new Promise((resolve) => {
        // console.log("coba" ,data);
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        axios
          .get(`${API_URL}/user/list-thread`, config)
          .then((res) => {
            resolve(res);
            const chat = res.data;
            console.log(res);
            context.commit("LIST_CHAT", chat);
            context.state.loadChat = false;
          })
          .catch((error) => {
            console.log(error);
            console.log("masuk catch");
          });
      });
    },

    // delete chat
    deleteChat(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .post(`${API_URL}/user/delete-pesan`, data, config)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },

    //get list contact
    listContact(context, id) {
      return new Promise((resolve) => {
        // console.log(data);
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        axios
          .get(`${API_URL}/user/${id}/list-kontak`, config)
          .then((res) => {
            resolve(res);
            const conatct = res.data;
            console.log(conatct);
            context.commit("LIST_CONTACT", conatct);
          })
          .catch((error) => {
            console.log(error);
            console.log("masuk catch");
          });
      });
    },
    //send link forgot pwd
    sendLink(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .post(`${API_URL}/password/reset-request`, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    // api forgot paswd
    resetPassword(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        axios
          .post(`${API_URL}/password/reset`, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },

    // buka kasus
    unlockCase(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        axios
          .post(`${API_URL}/user/unlock-kasus`, data, config)
          .then((res) => {
            resolve(res);
            console.log(res.data);
            const data = res.data;
            context.commit("BADGES_CASE", data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    // buka berita pendukung
    unlockNews(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/user/unlock-berita`, data, config)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    // list tugas indivdu
    listSubmissionY(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/list-tugas-individu`, data, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            console.log(data);
            context.commit("SUBMISSION_Y", data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    // list tugas kelompok
    listSubmissionX(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/list-tugas-grup`, data, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            console.log(data);
            context.commit("SUBMISSION_X", data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    //tambah 100poin ketika melengkapi profile
    addPointProfile(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/update-profile`, data, config)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    // log feed
    theFeeds(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        axios
          .post(`${API_URL}/list-log`, data, config)
          .then((res) => {
            resolve(res);
            const logs = res.data;
            console.log(logs.data);
            context.commit("LAST_PAGE_LOG", logs);
            if (data.page > 1) {
              context.state.feeds.push(...logs.data);
            } else {
              context.state.feeds = logs.data;
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("masuk catch");
          });
      });
    },
    unlockTugasIndividu(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/user/unlock-upload-individu`, data, config)
          .then((res) => {
            resolve(res);
            console.log(res);
            context.commit("BADGES_TUGAS", res.data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    unlockTugasKelompok(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/user/unlock-upload-grup`, data, config)
          .then((res) => {
            resolve(res);
            console.log(res);
            context.commit("REPONSE_TUGAS_KELOMPOK", res.data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
    returnBadge(context, data) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_GRAPHQL;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(`${API_URL}/return-badge`, data, config)
          .then((res) => {
            resolve(res);
            // console.log(res.data);
            if (data.tipe == "task") {
              context.commit("BADGES_TASK", res.data);
            }
            if (data.tipe == "poin") {
              context.commit("BADGES_POIN", res.data);
            }
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error);
            console.log("masuk catch");
          });
      });
    },
  },
  modules: {
    leaderboard,
    game,
    feedback,
    existUser,
    broadcast,
  },
});
